import { useEffect, useState } from "react";
import "./App.css";

function App() {
  const [query, setQuery] = useState("");

  useEffect(() => {
    const input = document.getElementById("input");
    input.focus();
    const app = document.getElementsByClassName("App")[0];
    app.style.opacity = 1;
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    window.open(
      "https://cse.google.com/cse?cx=ae9362ae18f003da9#gsc.tab=0&gsc.q=" +
        query +
        "&gsc.sort=date%3Aa"
    );
  };
  return (
    <div className="App">
      <div className="oldest-search">
        Made with ❤️ by{" "}
        <a
          href="https://ae.studio?utm_source=sds&utm_medium=referral&utm_campaign=oldestsearch&utm_content=top-bar&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252"
          target="_blank"
        >
          ae.studio
        </a>
      </div>
      <h1 className="title">
        <a
          href="https://www.producthunt.com/posts/oldest-search?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-oldest&#0045;search"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=371010&theme=light"
            alt="Oldest&#0032;Search - Search&#0032;for&#0032;the&#0032;oldest&#0032;results&#0032;on&#0032;internet&#0046; | Product Hunt"
            width="250"
            height="54"
          />
        </a>
      </h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={query}
          id="input"
          required
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search for the oldest results on internet..."
        />
        <button type="submit">
          <img src="https://oldestsearch.com/search.png" alt="Search" />
        </button>
      </form>
    </div>
  );
}

export default App;
